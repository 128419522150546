<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-10 p-5"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div>
              <div class="grid grid-cols-1 gap-0 lg:gap-4 lg:grid-cols-3">
                <div class="w-full">
                  <div
                    class="bg-gray-900 w-full text-gray-100 border border-gray-500 p-4 rounded"
                  >
                    <div class="mt-2">
                      <h3 class="text-xs text-gray-300">Prompt</h3>

                      <div
                        class="flex justify-center p-3 rounded bg-gray-800 mt-2"
                      >
                        <input
                          v-model="promptGenerate"
                          placeholder="Prompt"
                          class="flex-grow outline-none text-sm bg-transparent text-gray-200"
                          type="String"
                        />
                      </div>

                      <h3 class="text-xs text-gray-300 mt-5">
                        Negative Prompt
                      </h3>

                      <div
                        class="flex justify-center p-3 rounded bg-gray-800 mt-2"
                      >
                        <input
                          v-model="negative_prompt"
                          placeholder="Negative Prompt"
                          class="flex-grow outline-none text-sm bg-transparent text-gray-200"
                          type="String"
                        />
                      </div>

                      <h3 class="text-xs text-gray-300 mt-5">
                        Advanced setting
                      </h3>

                      <div class="grid grid-cols-2 gap-2 text-xs mt-5">
                        <div class="">
                          <h3 class="text-gray-400">Width</h3>

                          <div class="flex pt-2 rounded">
                            <input
                              v-model="imgWidth"
                              placeholder="512"
                              class="outline-none text-sm bg-transparent text-gray-200"
                              type="Number"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="">
                          <h3 class="text-gray-400">Height</h3>

                          <div class="flex pt-2 rounded">
                            <input
                              v-model="imgHeight"
                              placeholder="512"
                              class="outline-none text-sm bg-transparent text-gray-200"
                              type="Number"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div class="grid grid-cols-2 gap-2 text-xs mt-5">
                        <div class="">
                          <h3 class="text-gray-400">Prompt strength</h3>

                          <div class="flex pt-2 rounded">
                            <i class="fas fa-stream mt-1"></i>
                            <input
                              v-model="Prompt_strength"
                              placeholder="Auto"
                              class="outline-none text-sm bg-transparent text-gray-200 ml-2"
                              type="String"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="">
                          <h3 class="text-gray-400">Generation steps</h3>

                          <div class="flex pt-2 rounded">
                            <input
                              v-model="Generation_steps"
                              placeholder="40"
                              class="outline-none text-sm bg-transparent text-gray-200"
                              type="Number"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div class="text-xs mt-5">
                        <h3 class="text-gray-400">Seed</h3>

                        <div class="flex pt-2 rounded">
                          <i class="fas fa-seedling mt-1"></i>

                          <input
                            v-model="seed"
                            placeholder="Auto"
                            class="outline-none text-sm bg-transparent text-gray-200"
                            type="String"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      @click="generateAI()"
                      class="cursor-pointer w-full inline-block mt-5 text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-blue-600 to-pink-500 text-center"
                    >
                      <i class="fas fa-paint-brush mr-1"></i>Dreems
                    </div>
                  </div>

                  <div class="w-full mt-3">
                    <div class="text-gray-200">
                      <h3
                        class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500"
                      >
                        Credits: {{ getUser.credits_recovery }}
                      </h3>
                      <a class="text-xs underline mt-2" href="/buyCredit">
                        <i class="fas fa-cart-arrow-down mr-1"></i>Buy Credits
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-span-2 mt-5 lg:mt-0">
                  <div v-if="dataGen.length > 0">
                    <div v-for="data in dataGen" :key="data">
                      <div class="grid grid-cols-2 gap-4 lg:grid-cols-2">
                        <div
                          class="transition cursor-pointer rounded-sm rounded"
                          v-for="s in data.output"
                          :key="s"
                        >
                          <div
                            class="p-0.5 bg-opacity-25 bg-gradient-to-tr from-pink-500 via-green-500 to-red-500 rounded"
                          >
                            <a
                              :href="
                                '/detailProduct/' +
                                data._id +
                                '/' +
                                s.slice(s.length - 15, s.length)
                              "
                            >
                              <img :src="s" alt="Image 1" class="w-full" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="flex justify-center items-center cursor-pointer h-48 lg:h-96"
                    >
                      <div>
                        <img
                          src="https://media.discordapp.net/attachments/1080058777971212370/1083317410674769940/PromptDue_Logo_64x64px_02.png?width=128&height=128"
                          alt="Image 1"
                          class="rounded"
                        />
                        <div class="text-center">
                          <h3 class="text-gray-200 text-sm mt-3">PromptDue</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StickFooter class="mt-48" />
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import Generate from "@/api/generate";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      promptGenerate: null,
      negative_prompt: null,
      dataGen: [],
      imgWidth: "512",
      imgHeight: "512",
      Prompt_strength: "Auto",
      Generation_steps: "40",
      seed: "Auto",
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions(["setUser"]),
    async generateAI() {
      let timerInterval;

      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 8000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
        }
      });

      await Generate.create({
        user_id: this.getUser._id,
        negative_prompt: this.negative_prompt,
        prompt: this.promptGenerate,
        imgWidth: this.imgWidth,
        imgHeight: this.imgHeight,
        Prompt_strength: this.Prompt_strength,
        Generation_steps: this.Generation_steps,
        seed: this.seed,
      })
        .then((res) => {
          this.getDataImage();

          this.setUser(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDataImage() {
      await Generate.genList({
        address: this.getUser.address,
      })
        .then((res) => {
          this.dataGen = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getDataImage();
  },
};
</script>
